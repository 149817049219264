<template>
  <v-sheet>
    <v-card elevation="0">
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="filters.keywords"
                :label="$t('component.bookSearchDialog.fields.keywords.title')"
                :items="keywords"
                clearable
                outlined
                multiple
                chips
                deletable-chips
                small-chips
                persistent-placeholder
              ></v-select>
              <!-- <v-select
            
            :label="$t('component.bookSearchDialog.fields.authors.title')"
          ></v-select> -->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="reset">{{ $t("app.actions.reset") }}</v-btn>
        <v-btn color="success" @click="apply">{{
          $t("app.actions.apply")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>
<script>
import dialog from "../../mixins/dialog";
import { bus } from "../../main";
import cleanObjectFn from "../../utils/clean-object";
export default {
  components: {},
  mixins: [dialog],
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    // hasFilters() {
    //   var cl = cleanObjectFn(this.filters);
    //   return Object.keys(cl) > 0;
    // },
    keywords() {
      return this.$store.getters["utils/keywords"];
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        this.$router.replace({ query: { ...val } }).catch((e) => {});
      },
    },
  },
  methods: {
    apply() {
      bus.$emit("bookSearchUpdated", this.filters);
      this.close();
    },
    reset() {
      this.filters = {};
      bus.$emit("bookSearchUpdated", {});
    },
  },
  created() {
    this.$store.dispatch("utils/loadKeywords");
    this.filters = this.$route.query;
    delete this.filters.bookTitle;
    // if (this.parameter && this.parameter.filters) {
    //   console.log(this.parameter.filters);
    //   this.filters = { ...this.parameter.filters };
    // }
  },
};
</script>